


















































































import {
  reactive,
  defineComponent,
  watch,
  onMounted,
  onUnmounted,
} from "@vue/composition-api"

import { Editor, EditorContent } from "tiptap"
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Strike,
  Underline,
  History,
} from "tiptap-extensions"
import Realtime from "./Realtime"
import TextColor from "@/components/textAreas/TextColor"
import Indent from "@/components/textAreas/Indent"
import Link from "@/components/textAreas/Link"
import MenuBar from "@/components/textAreas/MenuBar.vue"
import MenuBubble from "./MenuBubble.vue"
import MaxSize from "@/components/textAreas/MaxSize"
import Loading from "@/components/Loading.vue"
import TextButton from "@/components/buttons/TextButton.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { Meeting, MeetingStructure } from "@/models/Meeting"
import { Limitation } from "@/models/Project"
import { meetingContainer } from "@/containers/MeetingsContainer"
import * as Y from "yjs"
import SofieWebsocketProvider from "./SofieWebsocketProvider"
import TeamsContext from "@/models/TeamsContext"
import stringToColor from "string-to-color"
import * as awarenessProtocol from "y-protocols/awareness"
import { memoSyncContainer } from "@/containers/MemoSyncContainer"
import { alertContainer } from "@/containers/AlertContainer"
import { WorkflowIn } from "@/models/Workflow"
import RevertMemoModal from "./Modal/RevertMemoModal.vue"
import { projectsContainer } from "@/containers/ProjectsContainer"
import ApiError from "@/models/Errors/ApiError"

interface State {
  editable: boolean
  readonlyMessage: { index: number; message: string }
  clientIds: Array<string>
  isError: boolean
  loading: boolean
  editor: typeof Editor | null
  provider: SofieWebsocketProvider | null
  selectNode: typeof Object | null
  isProperClosing: boolean
  connectError: number
}

let updateInterval: number | null = null
let updateData: number | null = null

const message = {
  readonly: {
    index: 0,
    message: "ネットワークの接続が切れたため読み取り専用になりました。",
  },
  tooMany: {
    index: 0,
    message:
      "データ保存容量が足りないため、読み取り専用になりました。今すぐメモの内容をコピーし退避してください。",
  },
}

export default defineComponent({
  props: {
    currentWorkflow: {
      type: WorkflowIn,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    meeting: {
      type: Meeting,
      required: true,
    },
    parent: {
      type: MeetingStructure,
      required: true,
    },
    revertMemoModalOpen: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MenuBar,
    MenuBubble,
    Loading,
    I18nFormattedMessage,
    EditorContent,
    TextButton,
    RevertMemoModal,
  },
  setup(props, { emit }) {
    const state = reactive<State>({
      clientIds: [],
      editable: true,
      readonlyMessage: message.readonly,
      isError: false,
      loading: true,
      editor: null,
      provider: null,
      selectNode: null,
      isProperClosing: false,
      connectError: 0,
    })
    const { getContext, getMembers } = teamsContextContainer.useContainer()
    const { getProjectAsync } = projectsContainer.useContainer()
    const { saveAsync, negotiateAsync } = memoSyncContainer.useContainer()
    const {
      setMemoValue,
      getMemosAsync,
      getOrCreateCarryOverMemo,
    } = meetingContainer.useContainer()
    const { showWarningMessage } = alertContainer.useContainer()

    const setCurrentTimeToUpdateTime = () => {
      updateData = new Date().getTime()
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updateMemo = (obj: { state: any }) => {
      const slice = obj.state.selection.content()
      const nodes = slice.content.content
      state.selectNode = Object.assign({
        type: "doc",
        content: JSON.parse(JSON.stringify(nodes)),
      })
      const html = state.editor.getHTML()
      setMemoValue(html)
      setCurrentTimeToUpdateTime()
    }

    const createRealTimeOption = async (
      context: TeamsContext,
      tokenUrl: {
        accessToken: string
        baseUrl: string
        url: string
      },
      refresh: () => void
    ) => {
      const members = await getMembers()
      const userName =
        members.find(m => m.userId === context.userObjectId)?.displayName ??
        "UnKnown"
      const ydoc = new Y.Doc()
      const awareness = new awarenessProtocol.Awareness(ydoc)
      awareness.setLocalState({
        user: {
          name: userName,
          color: stringToColor(userName),
        },
      })
      state.provider = new SofieWebsocketProvider(
        tokenUrl.baseUrl,
        "",
        ydoc,
        {
          awareness,
          // eslint-disable-next-line @typescript-eslint/camelcase
          params: { access_token: tokenUrl.accessToken },
        },
        refresh
      )
      return {
        ydoc,
        websocketProvider: state.provider,
      }
    }

    const destroyEditorAsync = async (itemid: string) => {
      state.isProperClosing = true
      if (updateInterval !== null) window.clearInterval(updateInterval)
      state.provider?.destroy()
      state.editor?.destroy()
      if (state.editable) {
        const context = await getContext()
        const project = await getProjectAsync(context.entityId)
        if (project)
          await saveAsync(
            project.siteId,
            project.groupId,
            context.entityId,
            itemid
          )
      }
    }

    const initEditor = async (itemid: string) => {
      if (state.editor) state.editor.off("transaction", updateMemo)
      emit("initializingEditor")
      state.loading = true
      const templates = await getMemosAsync(props.parent.id)
      if (itemid !== props.meeting.sharepointItemId) return
      const templatesHTML =
        templates.find(t => t.id === props.parent.selectedMemoTemplateId)
          ?.body || ""
      setMemoValue("")
      const context = await getContext()
      const project = await getProjectAsync(context.entityId)
      if (itemid !== props.meeting.sharepointItemId) return
      if (!context.userObjectId) throw new Error("Please Login")
      if (!project.groupId) throw new Error("Group Only")
      const tokenUrl = await negotiateAsync(
        project.siteId,
        project.groupId,
        context.entityId,
        itemid,
        context.userObjectId,
        templatesHTML
      )
      if (itemid !== props.meeting.sharepointItemId) return
      const realTimeParames = await createRealTimeOption(
        context,
        tokenUrl,
        () => {
          state.loading = true
          destroyEditorAsync(itemid).then(() => {
            initEditor(itemid)
          })
        }
      )
      if (itemid !== props.meeting.sharepointItemId) return
      state.provider?.on(
        "status",
        (event: { status: "connected" | "disconnected" }) => {
          if (event.status === "connected") {
            state.connectError = 0
            emit("initializedEditor")
            state.loading = false
            if (state.isProperClosing) {
              state.isProperClosing = false
            }
          } else {
            state.connectError += 1
            state.editable = false
            state.readonlyMessage = message.readonly
            state.editor?.setOptions({ editable: false })
            state.isProperClosing = true
          }
        }
      )
      state.isProperClosing = false
      const isNoLimitMode =
        !!project?.hasFlag(Limitation.CharacterLimit) || false
      state.editor = new Editor({
        editable: !props.readonly,
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link({
            target: "_blank",
          }),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Table({
            resizable: true,
          }),
          new TableHeader(),
          new TableCell(),
          new TableRow(),
          new Realtime(realTimeParames),
          new MaxSize({
            maxSize: isNoLimitMode ? 15000 : 9000,
            onMaxSizeOver: () => {
              showWarningMessage("最大文字数を超えました")
            },
          }),
          new TextColor(),
          new Indent(),
        ],
        content: "",
      })

      // 30秒に1回保存処理を実施する。
      updateInterval = window.setInterval(async () => {
        // 自身の最終更新時間から10秒以上経過してる場合は送信
        if (
          state.editable &&
          updateData &&
          new Date().getTime() > updateData + 10000
        ) {
          const context = await getContext()
          const project = await getProjectAsync(context.entityId)
          if (project?.groupId) {
            try {
              await saveAsync(
                project.siteId,
                project.groupId,
                context.entityId,
                itemid
              )
            } catch (e) {
              if (e instanceof ApiError) {
                if (e.status === 507) {
                  state.editable = false
                  state.readonlyMessage = message.tooMany
                  state.editor?.setOptions({ editable: false })
                }
              }
              throw e
            }
          }
          updateData = null
        }
      }, 30000)
      state.editor.on("transaction", updateMemo)
      state.editable = true
    }

    const reConnection = async () => {
      destroyEditorAsync(props.meeting.sharepointItemId).finally(() =>
        initEditor(props.meeting.sharepointItemId)
      )
    }

    const onRevertMemoOk = async (memo: { version: number; doc: unknown }) => {
      if (!state.editable) return
      state.editor?.setContent(memo.doc)
      const context = await getContext()
      const project = await getProjectAsync(context.entityId)
      saveAsync(
        project.siteId,
        project.groupId,
        context.entityId,
        props.meeting.sharepointItemId
      )
      emit("revertMemoModalClose")
    }

    // readonlyとmeeting.sharepointItemIdを別々でチェックして両方が変わって
    // destroy+initとeditable変更を同時にした場合はeditorが完全に死んで会議切り替え
    // だけでは復活しない（ページリロードが必要）
    //（meeting.sharepointItemIdが変わってもwatchが動かなくなる感じだった）
    //
    // そのために一緒にwatchするようにした。
    watch(
      () => ({
        readonly: props.readonly,
        sharepointItemId: props.meeting.sharepointItemId,
      }),
      async (newValue, prevValue) => {
        if (newValue.sharepointItemId !== prevValue.sharepointItemId) {
          state.loading = true
          await destroyEditorAsync(prevValue.sharepointItemId)
          await initEditor(newValue.sharepointItemId)
        } else if (newValue.readonly !== prevValue.readonly) {
          state.readonlyMessage = message.readonly
          state.editor?.setOptions({ editable: !newValue.readonly })
        }
      }
    )

    onMounted(async () => {
      if (props.meeting.sharepointItemId) {
        const context = await getContext()
        const project = await getProjectAsync(context.entityId)
        if (!project.siteId) throw new Error("siteId Is Not Setted")
        await Promise.all([
          getOrCreateCarryOverMemo(
            project.siteId,
            context.entityId,
            props.parent
          ),
          initEditor(props.meeting.sharepointItemId),
        ])
      }
    })
    const active = () => {
      if (state.editor) {
        state.editor.focus()
      }
    }
    onUnmounted(async () => {
      state.editor?.off("transaction", updateMemo)
      await destroyEditorAsync(props.meeting.sharepointItemId)
    })
    const onEditMemo = async () => {
      emit("clickEdit")
    }

    const setMove = (e: DragEvent) => {
      e.preventDefault()
      if (e.dataTransfer)
        if (e.pageX < 340) e.dataTransfer.dropEffect = "none"
        else e.dataTransfer.dropEffect = "move"
    }

    return {
      state,
      active,
      onRevertMemoOk,
      onEditMemo,
      setMove,
      reConnection,
    }
  },
})


























































































import { defineComponent, reactive, watch } from "@vue/composition-api"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import I18nFormattedDateTime from "@/components/i18n/I18nFormattedDateTime.vue"
import Loading from "@/components/Loading.vue"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import RichEditor from "@/components/textAreas/RichEditor.vue"
import DateTime from "@/models/DateTime"
import DefaultButton from "@/components/buttons/DefaultButton.vue"
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue"
import { projectsContainer } from "@/containers/ProjectsContainer"

interface VersionInfo {
  id: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  createdDateTime: DateTime | null
}

interface State {
  isLoading: boolean
  versions: Array<VersionInfo>
  openItems: Array<number>
  selectedVersion: VersionInfo | null
}

export default defineComponent({
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    listItemId: {
      type: String,
      required: true,
    },
  },
  components: {
    Loading,
    DefaultDialog,
    I18nFormattedMessage,
    I18nFormattedDateTime,
    RichEditor,
    DefaultButton,
    ConfirmDialog,
  },
  setup(props, { emit }) {
    const { getMemoVersionsLatestFirstAsync } = meetingContainer.useContainer()
    const { getContext } = teamsContextContainer.useContainer()
    const { getProjectAsync } = projectsContainer.useContainer()

    const state = reactive<State>({
      isLoading: false,
      versions: [],
      openItems: [],
      selectedVersion: null,
    })

    const loadMemoVersionsAsync = async () => {
      const context = await getContext()
      const project = await getProjectAsync(context.entityId)
      const versions = await getMemoVersionsLatestFirstAsync(
        project.siteId,
        context.entityId,
        props.listItemId
      )
      state.versions = versions.slice(1)
    }

    const initModalAsync = async () => {
      try {
        state.selectedVersion = null
        state.versions = []
        state.openItems = []
        state.isLoading = true
        await loadMemoVersionsAsync()
        if (state.versions.length > 0) state.openItems = [0]
      } finally {
        state.isLoading = false
      }
    }

    const onOk = async () => {
      if (!state.selectedVersion) throw new Error("復元するバージョンがない")
      emit("click:ok", state.selectedVersion.value)
      state.selectedVersion = null
    }

    watch(
      () => props.open,
      newValue => {
        if (newValue) {
          initModalAsync()
        }
      }
    )

    return {
      state,
      onOk,
    }
  },
})

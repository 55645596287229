import { Extension, Plugin, TextSelection } from "tiptap"

export default class MaxSize extends Extension {
  constructor(obj: { maxSize: number; onMaxSizeOver: () => void }) {
    super(obj)
  }

  get name() {
    return "maxSize"
  }

  get defaultOptions() {
    return {
      maxSize: 9000,
      onMaxSizeOver: null,
    }
  }

  get plugins() {
    return [
      new Plugin({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        appendTransaction: (_: any, oldState: any, newState: any) => {
          let max = this.options.maxSize || this.defaultOptions.maxSize
          max = max + 2
          const oldDocSize = oldState.doc.content.size
          const newDocSize = newState.doc.content.size

          const newResPos = newState.selection.$head

          if (newDocSize > oldDocSize && newDocSize > max) {
            const overPaste = newDocSize - max
            const newTextSelection = TextSelection.create(
              newState.doc,
              newResPos.pos - overPaste > 0 ? newResPos.pos - overPaste : 0,
              newResPos.pos
            )
            const newTr = newState.tr
            newTr.setSelection(newTextSelection)
            newTr.deleteSelection()
            if (this.options.onMaxSizeOver) this.options.onMaxSizeOver()
            return newTr
          }
        },
      }),
    ]
  }
}

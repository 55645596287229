






















import { defineComponent } from "@vue/composition-api"
import DefaultDialog from "./DefaultDialog.vue"

export default defineComponent({
  components: { DefaultDialog },
  props: {
    customName: String,
    visible: Boolean,
    title: String,
    body: String,
    maxWidth: {
      type: [String, Number],
      default: 400,
    },
    scrollable: Boolean,
  },
  setup(_, { emit, slots }) {
    if (!slots["dialogBody"]) throw new Error("no-dialogBody-slot")

    const onOk = () => {
      emit("ok")
    }

    const onCancel = () => {
      emit("cancel")
    }

    return { onOk, onCancel }
  },
})

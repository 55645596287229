import * as Y from "yjs"
import * as awarenessProtocol from "y-protocols/awareness"
import * as syncProtocol from "y-protocols/sync"
import { WebsocketProvider } from "y-websocket"
import * as encoding from "lib0/encoding"
import * as decoding from "lib0/decoding"
import * as time from "lib0/time"

const messageSync = 2
const messageMaxByte = 4000
const caches = new Map()
const refresh = 9

const marge = (items: Array<Uint8Array>) => {
  let sumLength = 0
  const currentItems = items
  for (let i = 0; i < currentItems.length; ++i) {
    sumLength += currentItems[i].length
  }
  const whole = new Uint8Array(sumLength)
  let pos = 0
  for (let i = 0; i < currentItems.length; ++i) {
    whole.set(new Uint8Array(currentItems[i]), pos)
    pos += currentItems[i].length
  }
  return whole
}

// メッセージが分割していた場合
// (分割フラグ)(このパケットの位置)(全体のパケット数)(実データ)の順番で送信
const broadcastMessage = (
  provider: SofieWebsocketProvider,
  buf: Uint8Array
) => {
  if (provider.wsconnected && provider.ws) {
    const partNum = Math.ceil(buf.length / messageMaxByte)
    for (let part = 0; part < partNum; part++) {
      const encoder = encoding.createEncoder()
      encoding.writeVarUint(encoder, messageSync)
      encoding.writeVarUint(encoder, part)
      encoding.writeVarUint(encoder, partNum)
      encoding.writeVarUint8Array(
        encoder,
        buf.slice(
          part * messageMaxByte,
          Math.min((part + 1) * messageMaxByte, buf.length)
        )
      )
      const data = encoding.toUint8Array(encoder)
      provider.ws.send(data)
    }
  }
}

const readMessage = (
  provider: SofieWebsocketProvider,
  buf: Uint8Array,
  emitSynced: boolean
) => {
  const encoder = encoding.createEncoder()
  const decoder = decoding.createDecoder(buf)
  const id = decoding.readVarString(decoder)
  const part = decoding.readVarUint(decoder)
  const maxPart = decoding.readVarUint(decoder)
  const contents = decoding.readVarUint8Array(decoder)
  const items: Array<Uint8Array> = caches.get(id) || new Array(maxPart)
  items[part] = contents
  let isEnough = true
  for (let i = 0; i < maxPart; i++) {
    isEnough = isEnough && !!items[i]
  }
  if (items.length !== maxPart || !isEnough) {
    caches.set(id, items)
    return encoder
  }
  caches.delete(id)
  const realValue = marge(items)
  const realDecoder = decoding.createDecoder(realValue)
  const messageType = decoding.readVarUint(realDecoder)
  const messageHandler = provider.messageHandlers[messageType]
  if (messageHandler) {
    messageHandler(encoder, realDecoder, provider, emitSynced, messageType)
  } else {
    console.error("Unable to compute message")
  }
  return encoder
}

export default class SofieWebsocketProvider extends WebsocketProvider {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _updatePartHandler: (update: Uint8Array, origin: any) => void

  connectBc() {
    // ws限定にするために
    // connectBcを無効化
  }

  constructor(
    serverUrl: string,
    roomname: string,
    doc: Y.Doc,
    {
      connect,
      awareness,
      params,
      WebSocketPolyfill,
      resyncInterval,
    }: {
      connect?: boolean
      awareness?: awarenessProtocol.Awareness
      params?: {
        [x: string]: string
      }
      WebSocketPolyfill?: typeof WebSocket
      resyncInterval?: number
    },
    refreshImpl: () => void
  ) {
    super(serverUrl, roomname, doc, {
      connect,
      awareness,
      params,
      WebSocketPolyfill,
      resyncInterval,
    })
    // 親の処理を削除
    this.doc?.off("update", this._updateHandler)
    this.messageHandlers[refresh] = refreshImpl
    this._updatePartHandler = (update, origin) => {
      if (origin !== this) {
        const encoder = encoding.createEncoder()
        syncProtocol.writeUpdate(encoder, update)
        broadcastMessage(this, encoding.toUint8Array(encoder))
      }
    }
    this.doc?.on("update", this._updatePartHandler)
    if (this.ws)
      this.ws.onmessage = event => {
        this.wsLastMessageReceived = time.getUnixTime()
        const encoder = readMessage(this, new Uint8Array(event.data), true)
        if (encoding.length(encoder) > 1) {
          if (this.ws) this.ws.send(encoding.toUint8Array(encoder))
        }
      }
  }
}
